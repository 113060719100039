import React from 'react';
import { Icon } from '../../../../resources';
import { PageLayout } from '../../../../components/page-layout/PageLayout';
import { FORM_SEARCH } from './constants/form';

function SearchInput(props) {
  const { onSearch, isLoading, options } = props;

  const onSubmit = (formValues) => {
    if (isLoading) {
      return;
    }

    const values = {
      caNumber: formValues?.caNumber || '',
      name: formValues?.name ? formValues?.name.trim() : '',
      keySuccess: formValues?.keySuccess || '',
      stageManual: formValues?.stageManual || '',
      stageAuto: formValues?.stageAuto || '',
      startDate: formValues?.lastedCall?.[0] || '',
      endDate: formValues?.lastedCall?.[1] || '',
      salesName: formValues?.salesName || '',
      followUpStartDate: formValues?.followUpDate?.[0] || '',
      followUpEndDate: formValues?.followUpDate?.[1] || '',
      calledStatus: formValues?.calledStatus || '',
    };

    onSearch(values);
  };

  return (
    <PageLayout
      searchLayout={{
        title: 'Telesales',
        isEnableEnterSubmit: true,
        formSearch: FORM_SEARCH({ options }),
        icon: <Icon.todo />,
        onClearCustom: () => onSubmit(),
        onSearch: (formValues) => {
          onSubmit(formValues);
        },
      }}
    />
  );
}

export default SearchInput;
